import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import modules from './modules'
Vue.use(Vuex);

export default new Vuex.Store({
  
  modules,
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    id: localStorage.getItem("id") || "",
    name: localStorage.getItem("name") || "",
    business: "",
    user: {},
  },
  mutations: {
    login(state, payload){
      state.userType = payload
    },
    business(state, businessId){
      state.businessId = businessId
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: process.env.VUE_APP_BASE_URL+"/auth/login",
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.token.value;
            const tokenExpiration = resp.data.token.expireTime;
            const id = resp.data.profile._id
            const name = resp.data.profile.firstName + " " + resp.data.profile.lastName
            commit("login")
            localStorage.setItem("token", token)
            localStorage.setItem("tokenExpiration", tokenExpiration);
            localStorage.setItem("id", id)
            localStorage.setItem("name", name)
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            commit("auth_success", token, user, id);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
            console.log(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {      // eslint-disable-line no-unused-vars
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        localStorage.removeItem("id");
        localStorage.removeItem("name");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
 
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status, 
  },
});