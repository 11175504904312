export default [
  {
    path: "/",
    name: "dashboard",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/dashboard.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
  },
  {
    path: "/register/employee/:token",
    name: "register",
    component: () => import("../views/pages/account/register"),
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
  },
  {
    path: "/reset",
    name: "Reset-password",
    component: () => import("../views/pages/account/reset-password"),
  },
  {
    path: "/admin/agents",
    name: "agents",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/agents.vue"),
  },
  {
    path: "/admin/transactions",
    name: "transactions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/transactions.vue"),
  },
  {
    path: "/admin/agent-transactions",
    name: "agent-transactions",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/agent-transactions.vue"),
  },
  {
    path: "/admin/agent-transactions/:id",
    name: "single-agent-transactions",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import("../views/pages/admin/single-agent-transactions.vue"),
  },
  // {
  //   path: "/admin/lockstate",
  //   name: "lock",
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   component: () => import("../views/pages/admin/lock.vue"),
  // },
  {
    path: "/admin/lock",
    name: "lockers",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/lockers.vue"),
  },
  {
    path: "/admin/daily-reports",
    name: "daily reports",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/dailyAgentReports.vue"),
  },
  {
    path: "/admin/adjustments",
    name: "adjustments",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/adjustments.vue"),
  },
  {
    path: "/admin/adjustments/approve/:id",
    name: "approve-adjustments",
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/pages/admin/approveAdjustments.vue"),
  },
  {
    path: "*",
    name: "404 Page",
    component: () => import("../views/pages/error-404.vue"),
  },
];


